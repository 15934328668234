import * as React from "react"
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { breakpoint } from "../components/dimensions"

import { ethers } from 'ethers';

const MintButton = styled.button`
  /* margin: 80px auto; */
  position: relative;
  z-index: 4;
  background-color: transparent;
  width: 200px;
  height: 70px;

  font-family: "Open Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

  border-radius: 5px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 5px;
  cursor: pointer;

  &:hover {
    border-radius: 5px;
    background-color: #FFFFFF;
    border: 2px solid transparent;
    padding: 5px;
    color:#FFB400;
  }

`

const MintForm = styled.form`
  margin: 80px auto;
  z-index: 4;
  position: relative;
  font-size: 20px;
  font-family: "Open Sans";
  text-align: center;
`

const MintAmountInput = styled.input`
  width: 20%;
  margin: 10px;
  font-size: 20px;
  font-family: "Open Sans";
  text-align: center;
`

const ToastMessage = styled.p`
`

const ToastLink = styled.a`

  display: inline-block;
  width: 80%;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: ${breakpoint}) {
      width: 50%;
      max-width: 300px;
  }
`

function ConnectNMintButton(props) {
  // States
  const [currentAccount, setCurrentAccount] = useState(null);
  const [buttonText, setButtonText] = useState('Connect');
  const [buttonDisable, setButtonDisable] = useState(false);
  const [amount, setAmount] = useState('1')


  // Constants
  const netId = props.netId;
  const abiJson = props.abiJson;
  const contractAddress = abiJson.networks[netId].address;
  const etherscanURLprefix = props.etherscanURLprefix;
  const abi = abiJson.abi;

  // Handlers
  const checkWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have Metamask installed!");
      return;
    } else {
      console.log("Wallet exists! We're ready to go!")
    }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account: ", account);
      setCurrentAccount(account);
      setButtonText('Mint')
    } else {
      console.log("No authorized account found");
    }
  }

  const connectWalletHandler = async () => {
    const { ethereum } = window;
    setButtonText('Connecting...')
    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log("Found an account! Address: ", accounts[0]);
      setButtonText('Connected')
      setCurrentAccount(accounts[0]);
      setButtonText('Mint')
    } catch (err) {
      console.log(err)
    }
  }

  const mintNftHandler = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        setButtonText('Minting...');
        setButtonDisable(true)


        console.log("contractAddress: ", contractAddress)

        const provider = new ethers.providers.Web3Provider(ethereum);
        
        console.log('network netId:', netId);

        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        const publicPrice = await nftContract.publicPrice();
        console.log('publicPrice: ', publicPrice);
        console.log('current account: ', currentAccount);
        console.log('amount: ', amount);
        let price = publicPrice.mul(amount);

        const nftContract_public = new ethers.Contract(contractAddress, abi, provider);
        let estGas = await nftContract_public.estimateGas.mint(currentAccount, amount, { value: price });
        console.log("estGas", estGas);

        let nftTxn = await nftContract.mint(currentAccount, amount, { value: price, gasLimit: estGas });
        let result = await nftTxn.wait();

        console.log('result: ', result)

        toast.success(
          <ToastMessage>
            Minted, see transation:{'\u00A0'}
            <ToastLink href={etherscanURLprefix + result.transactionHash} target="_blank" >
              {etherscanURLprefix + result.transactionHash}
            </ToastLink>
          </ToastMessage>
          ,
          {
            theme: "colored",
            autoClose: false,
            position: toast.POSITION.TOP_CENTER,
            closeOnClick: false
          }
        )
        setButtonText('Mint');
        setButtonDisable(false)

      } else {
        console.log("Ethereum object does not exist");
      }

    } catch (err) {
      console.log(err);
      setButtonText('Mint');
      setButtonDisable(false)
      toast.warn(
        <ToastMessage>
          {err.message.toString()}
        </ToastMessage>
        ,
        {
          theme: "colored",
          autoClose: false,
          position: toast.POSITION.TOP_CENTER,
          closeOnClick: false
        }
      );
    }
  }

  
  // UI component
  const connectWalletButton = () => {
    return (
      <MintButton onClick={connectWalletHandler} style={{ margin: "80px auto" }} >
      {buttonText}
      </MintButton>
    )
  }

  const mintNftButton = () => {
    return (
      <MintForm className="amount-form">
        <label htmlFor="amount">
          How many?{' '}
          <MintAmountInput
            type = "number"
            id="amount"
            defaultValue="1"
            min="1"
            max="99"
            onChange={event => {
              if (event.target.value < 99){
                setAmount(event.target.value)
              }
              else{
                event.target.value = 99;
                setAmount(99)
              }
            }
            }
            />
        </label>
        <br/>
        <MintButton type="submit" onClick={mintNftHandler} disabled={buttonDisable}>{buttonText}</MintButton>
      </MintForm>
    )
  }

  // Side effect
  useEffect(() => {
    checkWalletIsConnected();
  }, [])


  return (
    <div>
      {currentAccount ? mintNftButton() : connectWalletButton()}
    </div>
  )
}
export default ConnectNMintButton;
